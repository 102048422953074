import React from "react";
import SEO from "../components/seo";
import Hero from "../components/hero/hero";
import MailingList from "../components/mailingList/mailingList";
import Partners from "../components/partners/partners";
import Footer from "../components/footer/footer";

const IndexPage = () => (
    <>
        <SEO title="Dentuit" />
        <Hero/>
        {/* <MailingList/> */}
        <Partners/>
        <Footer/>
    </>
)

export default IndexPage
