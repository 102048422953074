import React from "react"
import "../../styles/global.css"
import "../../styles/reset.css"
import "../../styles/type.css"
import "./hero.css"
import logo from "../../images/logo.svg";
import check from "../../images/check.svg";
import Fade from 'react-reveal/Fade';


export default (props) => (
  <>
  <Fade>
  <header className="hero">
    <img src={logo} className="hero__logo" alt=""/>
    <h1 className="hero__lead">Take the uncertainty and individual bias out of <span className="brighter-font-hero">every</span> dental examination.</h1>
    <div className="hero__divider"></div>
    <p className="hero__paragraph">Our Mission is Applied Dental Artificial Intelligence <span id="new-line"></span>to Readily Advance the Best Possible Oral Health for Everyone.</p>
    <img src={check} className="hero__illustration" alt="consensus"/>
  </header>
  </Fade>
  </>
);