import React from "react"
import "../../styles/global.css"
import "../../styles/reset.css"
import "../../styles/type.css"
import "./partners.css"
import sponsor1 from "../../images/sponsor1.svg";
import sponsor2 from "../../images/sponsor2.svg";
import sponsor3 from "../../images/sponsor3.svg";
import sponsor4 from "../../images/sponsor4.svg";
// import sponsor5 from "../../images/sponsor5.svg";
import sponsor6 from "../../images/sponsor6.svg";
import sponsor7 from "../../images/sponsor7.svg";
import sponsor8 from "../../images/sponsor8.svg";
import sponsor9 from "../../images/sponsor9.svg";
import sponsor10 from "../../images/sponsor10.svg";

export default (props) => (
  <>
    <section className="about">
      <h2 className="about__txt">Dentistry must adapt to a new normal ... <br /><br /><span className="about-bolder">We are building a <span className="brighter-font-partners">consensus-based</span> paradigm for screening any dental patient with <span className="brighter-font-partners2">precision</span> and <span className="brighter-font-partners2">equity</span>.</span></h2>
      <div className="about__divider"></div>
      <article className="about__paragraph">
        <p className="about__paragraph--txt">Dentuit is an early-stage dental AI startup composed of technical founders, practicing dentists, strategic commercial partners, and a multidisciplinary team of academic clinicians and computer scientists. Together, we are unified now more than ever to innovate and future-proof the way dental care is accessed, delivered, and received.</p> 
      </article>
    </section>
    <section className="partners">
        <h2 className="partners__txt">Our Key Partners</h2>
        <div className="partners__wrapper">
            <a target="_blank" rel="noopener noreferrer" href="https://www.creativedestructionlab.com/">
              <img src={sponsor10} className="partners__img" alt=""/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://seedfund.nsf.gov/">
                <img src={sponsor1} className="partners__img" alt=""/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.umaryland.edu/">
                <img src={sponsor2} className="partners__img" alt=""/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.dental.umaryland.edu/">
                <img src={sponsor3} className="partners__img" alt=""/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://betamore.com/en">
                <img src={sponsor4} className="partners__img" alt=""/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://datatorch.io/">
                <img src={sponsor6} className="partners__img" alt=""/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.nvidia.com/en-us/deep-learning-ai/startups/">
                <img src={sponsor7} className="partners__img" alt=""/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://cloud.google.com/developers/startups">
                <img src={sponsor8} className="partners__img" alt=""/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.startupgrind.com/">
                <img src={sponsor9} className="partners__img" alt=""/>
            </a>
        </div>
    </section>
  </>
);