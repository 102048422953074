import React from "react"
import "../../styles/global.css"
import "../../styles/reset.css"
import "../../styles/type.css"
import "./footer.css"
import email from "../../images/email.svg";
import location from "../../images/location.svg";
import logo from "../../images/logo.svg";

export default (props) => (
  <>
    <footer className="footer">
        <section className="footer__contact">
            <h2 className="footer__head">Get In Touch</h2>
            <div className="footer__pairing footer__pairing--email">
                <img src={email} alt="" className="footer__icon"/>
                <p className="footer__txt">hello@dentuit.ai</p>
            </div>
            <div className="footer__pairing footer__pairing--email">
                <img src={location} alt="" className="footer__icon"/>
                <div className="footer__txt--wrapper">
                    <p className="footer__txt">101 W Dickman Street</p>
                    <p className="footer__txt">Baltimore, MD 21230</p>
                </div>
            </div>
        </section>
        <section className="footer__brand">
            <img src={logo} alt="" className="footer__logo"/>
            <div className="footer__txt--wrapper">
                <p className="footer__txt">Made with <span>&#9825;</span> in Charm City.</p>
                <p className="footer__txt">Copyright <span>&#169;</span> 2021 Dentuit, Inc. All Rights Reserved.</p>
            </div>
        </section>
    </footer>
  </>
);